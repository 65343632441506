/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Button } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Portfolio"}>
        <Column className="--center pb--80 pt--80" name={"uvod"} style={{"backgroundColor":"var(--color-custom-2)"}}>
          
          <ColumnWrap className="column__flex --center el--1 mt--16 flex--center" anim={null} style={{"maxWidth":1200}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim4 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"4"} animS={"3"} style={{"maxWidth":850}}>
              
              <Title className="title-box fs--72 w--900 title-box--invert" style={{"maxWidth":787}} content={"Portfolio"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--60 pt--60" name={"portfolio"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/168/img-2_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/i/template/168/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/168/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/168/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/168/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/168/img-2_s=2000x_.jpg 2000w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/168/img-1_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/i/template/168/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/168/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/168/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/168/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/168/img-1_s=2000x_.jpg 2000w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/168/img-2_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/i/template/168/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/168/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/168/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/168/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/168/img-2_s=2000x_.jpg 2000w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/168/img-1_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/i/template/168/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/168/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/168/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/168/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/168/img-1_s=2000x_.jpg 2000w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/168/img-2_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/i/template/168/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/168/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/168/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/168/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/168/img-2_s=2000x_.jpg 2000w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/168/img-1_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/i/template/168/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/168/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/168/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/168/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/168/img-1_s=2000x_.jpg 2000w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/168/fullscreen_s=350x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/i/template/168/fullscreen_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/168/fullscreen_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/168/fullscreen_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/168/fullscreen_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/168/fullscreen_s=2000x_.jpg 2000w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/168/img-1_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/i/template/168/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/168/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/168/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/168/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/168/img-1_s=2000x_.jpg 2000w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/168/img-2_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/i/template/168/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/168/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/168/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/168/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/168/img-2_s=2000x_.jpg 2000w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/168/img-1_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/i/template/168/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/168/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/168/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/168/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/168/img-1_s=2000x_.jpg 2000w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/168/img-2_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/i/template/168/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/168/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/168/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/168/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/168/img-2_s=2000x_.jpg 2000w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/168/img-1_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/i/template/168/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/168/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/168/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/168/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/168/img-1_s=2000x_.jpg 2000w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/168/img-2_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/i/template/168/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/168/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/168/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/168/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/168/img-2_s=2000x_.jpg 2000w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--30" name={"kontakt"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --shape5 --center el--1 pb--50 pt--50 flex--center" style={{"backgroundColor":"var(--white)"}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":595}}>
              
              <Title className="title-box fs--62" content={"Ozvěte se mi"}>
              </Title>

              <Button className="btn-box btn-box--shape4 fs--22 mt--16" content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--20" name={"paticka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left fs--20" content={"Petr Domanický"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--right fs--20" content={"Vytvořeno přes <a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}